<template>
  <div>
    <div :style="boxStyle" v-for="g in list" :key="g.id">
      <div :style="titleStyle">杭州灵隐寺固定资产登记卡</div>
      <div :style="detailStyle">
        <div :style="infoStyle">
          <div :style="{ ...infoItemStyle, ...(i > 0 ? {borderTop: '1px solid #000'} : {})}" v-for="(item, i) in g.infoList" :key="i">
            <div :style="infoLabelStyle">{{ item.label }}</div>
            <div :style="infoTextStyle">{{ item.value }}</div>
          </div>
        </div>
        <div :style="qrBoxStyle">
          <div :style="qrStyle">
            <vue-qr
              :text="g.asset_code"
              :margin="0"
              :size="258"
              :qid="g.id + ''"
              :callback="callback"
              v-if="g.asset_code"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import VueQr from "vue-qr";
    export default {
        name: "FixedTagPrint",
        props: {
            goods: [Object, Array]
        },
        components: { VueQr },
        data() {
            return {
                boxStyle: {
                    display: "flex",
                    flexDirection: "column",
                    width: "711px",
                    height: "450px",
                    borderRadius: "12px",
                    backgroundColor: "#fff",
                    border: "1px solid #000",
                    color: "#000",
                    pageBreakAfter: "always"
                },
                titleStyle: {
                    height: "125px",
                    fontSize: "44px",
                    lineHeight: "125px",
                    textAlign: "center",
                    fontWeight: "bold"
                },
                detailStyle: {
                    flex: 1,
                    display: "flex",
                    borderTop: "1px solid #000",
                },
                infoStyle: {
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "36px",
                    fontWeight: "bold"
                },
                infoItemStyle: {
                    flex: 1,
                    display: "flex",
                    alignItem: "center",
                },
                infoLabelStyle: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "108px",
                },
                infoTextStyle: {
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    padding: "0 20px",
                    borderLeft: "1px solid #000",
                },
                qrBoxStyle: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "281px",
                    borderLeft: "1px solid #000",
                },
                qrStyle: {
                    width: "258px",
                    height: "258px",
                }
            }
        },
        computed: {
            list() {
                let res = [];
                let goods = this.goods;
                if (goods) {
                    if (!Array.isArray(goods)) {
                        goods = [goods];
                    }
                    res = goods.map(g => {
                        return {
                            ...g,
                            infoList: [
                                { label: "名称", value: g.goods ? g.goods.name.replace(/\(.*?\)/g, "") : ""},
                                { label: "编号", value: g.asset_code || ""},
                                { label: "堂口", value: g.keTang ? g.keTang.name : g.position ? g.position.split("-")[0] : ""},
                            ]
                        };
                    })
                }
                return res;
            },
        },
        methods: {
            callback(url, qid) {
                const g = this.list.find(item => item.id == qid);
                if (g) {
                    g.loaded = true;
                    if (this.list.findIndex(item => !item.loaded) === -1) {
                        this.$emit("loaded");
                    }
                }
            }
        }
    }
</script>

<style lang="less">

</style>
